// extracted by mini-css-extract-plugin
export const activeLink = "header-module--activeLink---QkQ6";
export const container = "header-module--container--3lNyw";
export const iconContainer = "header-module--iconContainer--1DSf_";
export const burgerMenu = "header-module--burgerMenu--2lyNe";
export const burgerLineShort = "header-module--burgerLineShort--3eT6M";
export const burgerLineLong = "header-module--burgerLineLong--3pjjZ";
export const burgerMenuText = "header-module--burgerMenuText--1Y-W8";
export const widen = "header-module--widen--o5Uk7";
export const shorten = "header-module--shorten--3aMa7";
export const modalMenuExitBtn = "header-module--modalMenuExitBtn--W2XdK";
export const rotate = "header-module--rotate--3SkHk";
export const logo = "header-module--logo--3gIix";
export const logoImg = "header-module--logoImg--1S-Wq";
export const headerContact = "header-module--headerContact--2KJgK";
export const headerContactNumber = "header-module--headerContactNumber--3AmRf";
export const headerContactIcon = "header-module--headerContactIcon--39DPY";
export const phoneIcon = "header-module--phoneIcon--319SX";
export const phoneMove = "header-module--phoneMove--nfLKo";
export const modalOverlay = "header-module--modalOverlay--1ubsP";
export const modalContainer = "header-module--modalContainer--24s1S";
export const modalBackground = "header-module--modalBackground--3lMW-";
export const modal = "header-module--modal--9U_mP";
export const modalLogo = "header-module--modalLogo--3IFLN";
export const modalIconContainer = "header-module--modalIconContainer--3g0j7";
export const modalMenu = "header-module--modalMenu--28Cmz";
export const modalMenuItem = "header-module--modalMenuItem--3dEb1";
export const typewriter = "header-module--typewriter--2rDZl";
export const modalhome = "header-module--modalhome--3-KR2";
export const modalcompetitions = "header-module--modalcompetitions--2jrWH";
export const modalhowitworks = "header-module--modalhowitworks--1y9ay";
export const modalwinners = "header-module--modalwinners--1t7-y";
export const modalabout = "header-module--modalabout--Y4wUD";
export const modalcareers = "header-module--modalcareers--cXbO8";